import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
// import { createBrowserHistory } from "history";

// Components
import Home from "./Home";
import Resume from "./Resume";
// import Footer from "./common/Footer";

// Styles
import "./styles/App.css";

function App() {
  // const history = createBrowserHistory();

  // const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  // if (path) {
  //   history.replace(path);
  // }

  return (
    <div className="App">
      <BrowserRouter>
        <main className="main">
          {/* <Header></Header> */}
          <Route path="/" exact component={Home} />
          <Route path="/resume" component={Resume} />
          {/* <Footer></Footer> */}
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
