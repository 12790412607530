import React from "react";

function Home() {
  let year = new Date().getFullYear();
  return (
    <div>
      <div id="home">
        <h1>beb.com</h1>
      </div>
      <div>
        <small>
          <em>©{year}</em>
        </small>
      </div>
    </div>
  );
}

export default Home;
